#howToUse{
    width: 100%;
    text-align: center;
    font-weight: 500;
    padding: 5px 0px;
    background-color: rgb(240, 240, 240);
    border-radius: 10px 10px 0 0;
}

#listContainer{
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    border-radius: 0 0 0 10px;
    text-indent: 5px;
    
    #list{
        list-style-position: inside;
        list-style-type: decimal-leading-zero;
        overflow: hidden;

        p{
            display: inline;
            overflow: hidden;
            
        }

        li:hover{
            color: white;
            background-color: var(--color1);
            cursor: default;
        }
    }
}


/* --------- LIST SCROLLBAR ---------*/
::-webkit-scrollbar{
    width: 10px;
    background-color: rgb(247, 247, 247);
    border-radius: 0 0 5px 0;   
    }

::-webkit-scrollbar-thumb{
    height: 50px;
    background-color: rgb(200, 200, 200);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover{
    background-color: rgb(160, 160, 160);
}

/* MOZILA */
#breedsList{
    scrollbar-width: thin;
}