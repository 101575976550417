@import url('https://fonts.googleapis.com/css2?family=Merienda:wght@800&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

:root{
    --color1: rgb(51, 40, 56);
    --color2: rgba(0, 95, 133, 1);
    --color3: rgba(0, 104, 145, 0.75);
    --color4: rgb(21, 156, 209);
    --bottom-gradient: linear-gradient(to bottom, var(--color2), var(--color3));
    --app-title-font: 'Merienda', system-ui, sans-serif;
    --content-font: 'Open sans', system-ui, sans-serif;
}

*{
    margin: 0;
    padding: 0;
}

#background{    
    background-image: var(--bottom-gradient);
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

#header{
    color: white;
    font-family: var(--app-title-font);
    text-align: center;
    width: 100%;
    height: 10%;

    display: flex;
    align-items: center;
    justify-content: center;

    a{
        text-decoration: none;
        color: white;
    }

    @media screen and (width <= 350px) {
        height: 7.5%;
        font-size: 10px;
    }
    @media screen and (height <= 600px) {
        height: 7.5%;
        font-size: 10px;
    }
}

#dash{
    margin: 10px;
    height: 90%;
    width: 90%;
    overflow: hidden;
    max-width: 1000px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (height <= 600px) {
        padding: 3px;
    }
}

#footer{
    height: 5%;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;

    a{
        text-decoration: none;
        color: white;
        font-weight: bold;
    }
    a:hover{
        text-decoration: underline;
    }

    @media screen and (height <= 600px) {
        height: 2.5%;
        font-size: 14px;
    }
    @media screen and (width <= 350px) {
        height: 2.5%;
        font-size: 12px;
        padding: 3px 8px;
    }
}
