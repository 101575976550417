#cardContainer{
    background: white;
    height: 57.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    @media screen and (height <= 1200px) {
        height: 67.5%;
    }
    @media screen and (height <= 600px) {
        height: 70.5%;
    }
}

#listContainer{
    background:white;
    height: 37.5%;
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media screen and (height <= 1200px) {
        height: 27.5%;
    }
}