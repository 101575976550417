#card{
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

#name{
    font-size: 1.1rem;
    font-weight: 700;
    font-style: italic;
    text-align: center;
    padding: 5px;
    line-height: 1rem;

    @media screen and (height <= 600px) {
        font-size: 1rem;
        line-height: 0.95rem;
        padding: 2px;
    }
    @media screen and (width <= 400px) {
        font-size: 0.95rem;
    }
}

#info{
    height: 35%;
    padding: 5px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    *{
        height: fit-content;
    }

    p{
        line-height: 1.4rem;

        @media screen and (height <= 550px) {
            line-height: 1.2rem;
        }
    }

    mark{
        background-color: transparent;
        font-weight: bold;
    }

    @media screen and (height <= 600px) {
        justify-content: center;
        padding: 2px;
        font-size: 1rem;
    }
    @media screen and (width <= 400px) {
        font-size: 0.95rem;
    }
}


#imgBackground{
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: black;
    height: 70%;
    width: 100%;

    @media  screen and (height <= 1000px) {
        height: 60%;
    }
    @media  screen and (height <= 700px) {
        height: 50%;
    }
}


#imgContainer{
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 100%;
}


#img{
    max-height:100%;
    max-width: 100%;
}


#moreInfo{
    text-align: center;
    padding: 5px;
    font-weight: 500;
    font-style: italic;

    @media screen and (height <= 600px) {
        padding: 0 0 5px 0;
    }
}

